'use strict';

module.exports = {
    seeMoreButton: function () {
        $(document).on('click', '.read-more-button', function () {
            $(this).addClass('d-none');
            $(this).next().removeClass('d-none');
            $(this).parent().find('.customer-review, .pd-content-pet-info-text-main').addClass('expanded');
            $('.pd-content-pet-info').addClass('expanded');
        });
        $(document).on('click', '.read-less-button', function () {
            $(this).addClass('d-none');
            $(this).prev().removeClass('d-none');
            $(this).parent().find('.customer-review, .pd-content-pet-info-text-main').removeClass('expanded');
            $('.pd-content-pet-info').removeClass('expanded');
            $('html, body').animate({
                scrollTop:  $('.pd-content-pet-info').offset().top
            }, 500);
        });

        var $informationTab = $('.pd-content-pet-info-text-main');
        var $seeMoreButton = $('.read-more-button');

        if (window.innerWidth < 426) {
            if ($informationTab.text().length > 200) {
                $seeMoreButton.show();
            }
            else {
                $seeMoreButton.hide();
            }
        }
        else {
            if ($informationTab.text().length > 400) {
                $seeMoreButton.show();
            }
            else {
                $seeMoreButton.hide();
            }
        }

    }
};
