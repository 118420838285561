module.exports = {
    init: function () {
        const triggerOverlay = (overlayId, ingredientCard, elementClicked, buttonText = null) => {
            const targetDiv = $('#' + overlayId);
            if (targetDiv.length) {
                targetDiv.toggleClass('d-none active');
                $('body').addClass('no-scroll');
                
                const eventData = {
                    ingredientCard,
                    ingredientCardElementClicked: elementClicked
                };

                if (buttonText) {
                    eventData.buttonText = buttonText;
                }

                // Trigger the GTM event
                $('body').trigger('gtm:landingPageButton', eventData);
            }
        };

        // Event handler for CTA button click
        $(document).on('click', '.cta-button-wrapper button', function () {
            const overlayId = $(this).data('overlay-id');
            const buttonText = $(this).find('.button-text').text();
            const ingredientCard = $(this).closest('.ingredient-card').find('.custom-headline').text();

            if (overlayId) {
                triggerOverlay(overlayId, ingredientCard, 'CTAButton', buttonText);
            }
        });

        // Event handler for image click within ingredient card
        $(document).on('click', '.ingredient-card .custom-image-wrapper img', function () {
            const $ctaButton = $(this).closest('.ingredient-card').find('.cta-button-wrapper button');
            const overlayId = $ctaButton.data('overlay-id');
            const ingredientCard = $(this).closest('.ingredient-card').find('.custom-headline').text();

            if (overlayId) {
                triggerOverlay(overlayId, ingredientCard, 'image');
            }
        });

        // Event handler for overlay close button click
        $(document).on('click', '.overlay-inner-content .close-button', function () {
            const targetDiv = $(this).closest('.overlay');
            if (targetDiv.length) {
                targetDiv.toggleClass('d-none active');
                $('body').removeClass('no-scroll');
            }
        });

        // Event handler for landing page CTA link click
        $(document).on('click', 'a.pd-cta-button', function () {
            const buttonText = $(this).text();
            const buttonLink = $(this).attr('href');
            $('body').trigger('gtm:landingPageButton', { buttonLink, buttonText });
        });
    }
};
